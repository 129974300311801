<template>
  <div class="container"></div>
</template>

<script type="text/javascript">
import { mapActions, mapMutations } from "vuex";

export default {
  methods: {
    ...mapActions(["handleLogout"]),
    ...mapMutations(["setLoading"])
  },
  async mounted() {
    this.setLoading({ bool: true });
    await this.handleLogout();
    setTimeout(() => {
      this.$router.push({
        name: "Login"
      });
    }, 1000);
  },
  unmounted() {
    this.setLoading({ bool: false });
  }
};
</script>
